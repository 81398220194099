import gql from 'graphql-tag';

export default gql`
	query getRocItemsByResponsibilityArea(
		$rocModelId: String!
		$responsibilityArea: Int
		$ignoreSaq: Boolean
	) {
		list: get_api_ResponsibilityArea_GetItems_rocModelId(
			rocModelId: $rocModelId
			responsibilities: $responsibilityArea
			ignoreSaq: $ignoreSaq
		) @rest(path: "ResponsibilityArea/GetItems/{args.rocModelId}?{args}") {
			data {
				id
				responsibilityArea
				itemDescription
				numbering
				chapterId
			}
		}
	}
`;
