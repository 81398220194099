import Box from '@mui/material/Box';
import { css } from '@emotion/css/macro';
import { UserAvatar } from '../../ui/Avatar';
import { ProjectUserInfo } from '../types/api';

const avatarClass = css`
	margin-right: 8px;
`;

export default function ShortUserInfo({
	email,
	userName,
	firstName,
	lastName,
	name,
	noAvatar,
}: Partial<ProjectUserInfo & { name: string; noAvatar: boolean }>) {
	return (
		<Box display="flex" alignItems="center">
			{!noAvatar && email && <UserAvatar email={email} size="small" className={avatarClass} />}
			<Box display="flex" flexDirection="column" fontSize="12px">
				<span>
					{name ? (
						<>{name}</>
					) : (
						<>
							{firstName} {lastName}
						</>
					)}
				</span>
				{userName && userName !== email && <span>{userName}</span>}
				{email && <span>{email}</span>}
			</Box>
		</Box>
	);
}
